<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title">
                {{
                  $route.params.id
                    ? $t('customerInvoiceMsgs.editCustomerInvoice')
                    : $t('customerInvoiceMsgs.addCustomerInvoice')
                }}
              </h4>
            </div>
          </div>
          <div class="card-body">
            <div>
              <form @submit.prevent="onSubmit">
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="type">{{ $t('type') }}</label>

                    <b-form-select
                      id="type"
                      class="form-control form-control-b-select"
                      v-model="type"
                      required
                      :options="allTypeChoices"
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled>
                          {{ $t('customerInvoiceMsgs.typePlaceholder') }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="customer">
                      {{ $t('customerMsgs.customer') }}
                    </label>

                    <v-select
                      id="customer"
                      class="form-control v-select-custom"
                      label="name"
                      v-model="customer"
                      :reduce="(customer) => customer.id"
                      :placeholder="$t('customerInvoiceMsgs.customerPlaceholder')"
                      :options="allCustomers"
                      :loading="areCustomersLoading"
                      :filterBy="filterCustomers"
                      @input="onCustomerChange"
                    >
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!customer" v-bind="attributes" v-on="events" />
                      </template>

                      <template slot="option" slot-scope="option">
                        {{ option.name }}
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                  </div>

                  <div class="form-group col-md-12" v-if="type === WORKSHOP">
                    <label for="orders">
                      {{ $t('orderMsgs.orders') }}
                    </label>

                    <v-select
                      id="orders"
                      class="form-control v-select-custom"
                      label="order_number"
                      v-model="orders"
                      :placeholder="$t('customerInvoiceMsgs.orderPlaceholder')"
                      :options="allCustomerOrders"
                      :loading="areCustomerOrdersLoading"
                      :reduce="(order) => order.id"
                      multiple
                    >
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!orders.length" v-bind="attributes" v-on="events" />
                      </template>

                      <template slot="option" slot-scope="option">
                        <i>{{ option.order_number }}</i>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <i class="mr-1">{{ option.order_number }}</i>
                      </template>
                    </v-select>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="totalInvoiceAmount">
                      {{ $t('customerInvoiceMsgs.customerInvoiceTotalInvoiceAmount') }}
                    </label>
                    <currency-input
                      class="form-control"
                      id="totalInvoiceAmount"
                      :placeholder="$t('customerInvoiceMsgs.customerInvoiceTotalInvoiceAmount')"
                      v-model="totalInvoiceAmount"
                      required
                      :precision="2"
                      :allow-negative="false"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="skonto">
                      {{ $t('customerInvoiceMsgs.skonto') }}
                    </label>
                    <currency-input
                      class="form-control"
                      id="skonto"
                      :placeholder="$t('customerInvoiceMsgs.skonto')"
                      v-model="skontoPercent"
                      required
                      :precision="2"
                      :allow-negative="false"
                      :currency="{ suffix: ' %' }"
                      :value-range="{ min: 0, max: 100 }"
                    />
                  </div>
                  <div class="form-group col-md-12" v-if="type === 'workshop'">
                    <label for="invoiceNum">{{ $t('customerInvoiceMsgs.invoiceNum') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="invoiceNum"
                      :placeholder="$t('invoiceMsgs.invoiceNumPlaceholder')"
                      v-model="invoiceNum"
                      required
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label for="invoiceDate">
                      {{ $t('customerInvoiceMsgs.customerInvoiceDate') }}
                    </label>

                    <b-form-datepicker v-model="invoiceDate" :locale="getCurrLanguage" required></b-form-datepicker>
                  </div>

                  <div class="form-group" :class="skontoPercent ? 'col-md-6' : 'col-md-12'">
                    <label for="dueDate">
                      {{ $t('customerInvoiceMsgs.customerInvoiceDueDate') }}
                    </label>

                    <b-form-datepicker v-model="dueDate" :locale="getCurrLanguage" required></b-form-datepicker>
                  </div>

                  <div class="form-group col-md-6" v-if="!!skontoPercent">
                    <label for="dueDateSkonto">
                      {{ $t('customerInvoiceMsgs.dueDateSkonto') }}
                    </label>

                    <b-form-datepicker
                      v-model="dueDateSkonto"
                      :locale="getCurrLanguage"
                      required
                      :label-no-date-selected="$t('generalMsgs.noDateSelected')"
                    ></b-form-datepicker>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="monthOfFulfillment">
                      {{ $t('customerInvoiceMsgs.monthOfFulfillment') }}
                    </label>

                    <b-form-select
                      id="monthOfFulfillment"
                      class="form-control form-control-b-select"
                      v-model="monthOfFulfillment"
                      required
                      :options="allMonthsOptions"
                    ></b-form-select>
                  </div>

                  <div class="form-group col-md-12">
                    <label for="paymentStatus">{{ $t('customerInvoiceMsgs.paymentStatus') }}</label>

                    <b-form-select
                      id="paymentStatus"
                      class="form-control form-control-b-select"
                      v-model="paymentStatus"
                      required
                      :options="allPaymentStatusChoices"
                    >
                    </b-form-select>
                  </div>

                  <div class="form-group col-md-12 col-xxl-12">
                    <label for="invoiceFile">
                      {{ $t('customerInvoiceMsgs.invoiceFile') }}
                    </label>
                    <b-form-file
                      :placeholder="$t('customerInvoiceMsgs.attachInvoiceFile')"
                      :drop-placeholder="$t('generalMsgs.dropFilesPlaceholder')"
                      :browse-text="$t('generalMsgs.browse')"
                      class="form-control"
                      v-model="invoiceFile"
                      @input="uploadInvoiceFile"
                      v-if="!fileURL && !invoiceFile"
                    ></b-form-file>

                    <!-- Attached files -->
                    <div class="mt-2">
                      <div class="row" v-if="fileURL || invoiceFile">
                        <div :class="isUploading ? 'col-md-8 col-xxl-10' : 'col-md-10 col-xxl-11'">
                          <i class="fas fa-file text-primary mr-1" />
                          <file-download-link v-if="fileURL" :fileUrl="fileURL" />
                          <span v-else>{{ invoiceFile.name }}</span>
                        </div>
                        <div class="col-md-2 col-xxl-1 text-right" v-if="isUploading">
                          <b-progress :value="uploadPercent" :max="100" animated class="mt-1"></b-progress>
                        </div>
                        <div class="text-right col-md-2 col-xxl-1">
                          <a href="#" class="text-danger" @click.prevent="removeInvoiceFile()">
                            <i class="fas fa-times-circle" />
                            {{ $t('remove') }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="isLoading || areCustomersLoading || isUploading || areCustomerOrdersLoading"
                    style="min-width: 100px"
                  >
                    <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                    <span v-else>
                      {{ $route.params.id ? $t('update') : $t('add') }}
                    </span>
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import vSelect from 'vue-select';
import { parseFileNameFromUrl, formatToAPIDate } from '../../common/utils';
import fileDownloadLink from '../../components/files/fileDownloadLink';
import { CUSTOMER_INVOICE_TYPE_CHOICES } from '../../common/constants';

export default {
  name: 'PostCustomerInvoice',

  components: { fileDownloadLink, vSelect },

  data() {
    return {
      allCustomers: [],
      invoiceDate: formatToAPIDate(moment()),
      dueDate: formatToAPIDate(moment()),
      dueDateSkonto: formatToAPIDate(moment()),
      type: null,
      customerInvoice: null,
      paymentStatus: 'unpaid',

      orders: [],
      allCustomerOrders: [],
      areCustomerOrdersLoading: false,

      customer: null,
      invoiceFile: null,
      fileURL: null,
      totalInvoiceAmount: null,
      skontoPercent: 0,
      monthOfFulfillment: moment().month() + 1,

      isLoading: false,
      areCustomersLoading: false,
      isUploading: false,

      uploadPercent: 0,
      uploadCancelTokenSource: null,
      ...CUSTOMER_INVOICE_TYPE_CHOICES,
      invoiceNum: null,
    };
  },

  methods: {
    ...mapActions([
      'createCustomerInvoice',
      'updateCustomerInvoice',
      'getCustomerInvoice',
      'getAllCustomers',
      'getInvoiceFilePresignedUrl',
      'uploadToPresignedUrl',
      'getAllCustomerOrders',
    ]),

    parseFileNameFromUrl,

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },

    filterCustomers(option, _label, search) {
      const lowerSearch = search.toLowerCase();
      return (
        (option.name || '').toLowerCase().includes(lowerSearch) ||
        (option.customer_number || '').toLowerCase().includes(lowerSearch)
      );
    },

    async fetchCustomers(params = {}) {
      this.areCustomersLoading = true;
      const response = await this.getAllCustomers({
        ordering: 'name',
        ...params,
      });
      this.allCustomers = response.data;
      this.areCustomersLoading = false;
    },

    async fetchCustomerOrders(firstLoad = false) {
      this.areCustomerOrdersLoading = true;
      const response = await this.getAllCustomerOrders(this.customer);
      if (firstLoad) this.allCustomerOrders = this.allCustomerOrders.concat(response.data);
      else this.allCustomerOrders = response.data;
      this.areCustomerOrdersLoading = false;
    },

    removeInvoiceFile() {
      if (this.uploadCancelTokenSource) {
        this.uploadCancelTokenSource.cancel();
      }
      this.invoiceFile = this.fileURL = null;
      this.uploadPercent = 0;
    },

    async uploadInvoiceFile() {
      this.uploadCancelTokenSource = axios.CancelToken.source();
      this.isUploading = true;

      try {
        const urlResp = await this.getInvoiceFilePresignedUrl({
          file_name: this.invoiceFile.name,
          content_type: this.invoiceFile.type,
        });
        this.fileURL = urlResp.upload_url.split('?')[0];
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.invoiceFile,
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource.token,
          },
        });
      } catch (error) {
        this.fileURL = this.invoiceFile = null;
      }

      this.uploadCancelTokenSource = null;
      this.isUploading = false;
      this.uploadPercent = 0;
    },

    onCustomerChange() {
      if (this.customerInvoice && this.customer === this.customerInvoice.customer.id) {
        this.orders = this.customerInvoice.orders.map((order) => order.id);
        this.allCustomerOrders = this.customerInvoice.orders;
        this.fetchCustomerOrders(true);
      } else {
        this.orders = [];
        this.fetchCustomerOrders();
      }
    },

    async onSubmit() {
      this.isLoading = true;

      try {
        const customerInvoiceData = {
          customer: this.customer,
          total_invoice_amount: this.totalInvoiceAmount,
          invoice_date: this.invoiceDate,
          due_date: this.dueDate,
          payment_status: this.paymentStatus,
          type: this.type,
          month_of_fulfillment: this.monthOfFulfillment,
          invoice_file: this.fileURL,
          skonto_percent: this.skontoPercent,
          due_date_skonto: this.skontoPercent ? this.dueDateSkonto : null,
          invoice_number: this.type === CUSTOMER_INVOICE_TYPE_CHOICES.WORKSHOP ? this.invoiceNum : null,
          orders: this.type === CUSTOMER_INVOICE_TYPE_CHOICES.WORKSHOP ? this.orders : [],
        };
        if (this.$route.params.id) {
          await this.updateCustomerInvoice({
            id: this.$route.params.id,
            data: customerInvoiceData,
          });
          this.makeToast('success', this.$t('customerInvoiceMsgs.customerInvoiceUpdated'));
        } else {
          await this.createCustomerInvoice(customerInvoiceData);
          this.makeToast('success', this.$t('customerInvoiceMsgs.customerInvoiceAdded'));
        }
        this.isLoading = false;
        this.$router.push({ name: 'list-customer-invoices' });
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }

      this.isLoading = false;
    },
  },

  // Reset data on before entering the route.
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.allCustomers = vm.orders = vm.allCustomerOrders = [];
      vm.invoiceDate = formatToAPIDate(moment());
      vm.dueDate = formatToAPIDate(moment());
      vm.paymentStatus = 'unpaid';
      vm.customer =
        vm.type =
        vm.customerInvoice =
        vm.invoiceFile =
        vm.fileURL =
        vm.totalInvoiceAmount =
        vm.uploadCancelTokenSource =
          null;
      vm.monthOfFulfillment = moment().month() + 1;
      vm.uploadPercent = 0;

      vm.isLoading = vm.areCustomersLoading = vm.isUploading = false;

      vm.fetchCustomers();
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.uploadCancelTokenSource) {
      this.uploadCancelTokenSource.cancel();
    }
    next();
  },

  async mounted() {
    // this.fetchCustomers();
    if (this.$route.params.id) {
      try {
        const customerInvoice = (await this.getCustomerInvoice(this.$route.params.id)).data;
        this.customerInvoice = customerInvoice;
        this.customer = customerInvoice.customer.id;
        this.type = customerInvoice.type;
        this.totalInvoiceAmount = customerInvoice.total_invoice_amount;
        this.invoiceDate = customerInvoice.invoice_date;
        this.invoiceNum = customerInvoice.invoice_number;
        this.dueDate = customerInvoice.due_date;
        this.paymentStatus = customerInvoice.payment_status;
        this.allCustomerOrders = customerInvoice.orders || [];
        this.orders = customerInvoice.orders.map((order) => order.id) || [];
        this.fileURL = customerInvoice.invoice_file;
        this.skontoPercent = customerInvoice.skonto_percent;
        this.dueDateSkonto = customerInvoice.due_date_skonto || this.dueDateSkonto;
        this.monthOfFulfillment = customerInvoice.month_of_fulfillment;
      } catch (err) {
        this.$router.push({ name: 'not-found' });
      }
      this.fetchCustomerOrders(true);
    }
  },

  computed: {
    ...mapGetters(['getCurrLanguage']),

    allMonthsOptions() {
      return [
        { value: 1, text: this.$t('monthNames.jan') },
        { value: 2, text: this.$t('monthNames.feb') },
        { value: 3, text: this.$t('monthNames.march') },
        { value: 4, text: this.$t('monthNames.april') },
        { value: 5, text: this.$t('monthNames.may') },
        { value: 6, text: this.$t('monthNames.june') },
        { value: 7, text: this.$t('monthNames.july') },
        { value: 8, text: this.$t('monthNames.aug') },
        { value: 9, text: this.$t('monthNames.sep') },
        { value: 10, text: this.$t('monthNames.oct') },
        { value: 11, text: this.$t('monthNames.nov') },
        { value: 12, text: this.$t('monthNames.dec') },
      ];
    },
    allTypeChoices() {
      return [
        {
          value: 'workshop',
          text: this.$t('customerInvoiceTypeChoices.workshop'),
        },
        {
          value: 'installation',
          text: this.$t('customerInvoiceTypeChoices.installation'),
        },
      ];
    },
    allPaymentStatusChoices() {
      return [
        { value: 'paid', text: this.$t('paymentStatusChoices.paid') },
        { value: 'unpaid', text: this.$t('paymentStatusChoices.unpaid') },
      ];
    },
  },
};
</script>
